import React, { useEffect } from "react";
import { Grow, TextField, IconButton } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import styled from "styled-components";

const StyledTextField = styled(TextField)`
  margin-top: 8px !important;
`;

const StyledIconButton = styled(IconButton)`
  &:hover {
    color: red;
  }
`;

interface IProps {
  searchText: string;
  handleSearch: any;
  hideSearch: any;
  options: any;
  rowCount?: number;
}

const CustomSearchRender: React.FC<IProps> = ({
  searchText,
  handleSearch,
  hideSearch,
  options,
  rowCount,
}) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        hideSearch();
      }
    };

    // subscribe event
    document.addEventListener("keydown", handleKeyDown, false);

    return () => {
      // unsubscribe event
      document.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [hideSearch]);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearch(e.target.value);
  };

  return (
    <Grow in style={{ transformOrigin: "0 0 0" }} timeout={300}>
      <>
        <StyledTextField
          autoFocus
          placeholder={`Search ${rowCount} ${
            rowCount === 1 ? "row..." : "rows..."
          }`}
          InputProps={{
            "aria-label": options.textLabels.toolbar.search,
            color: "secondary",
          }}
          value={searchText || ""}
          onChange={handleTextChange}
          fullWidth={false}
        />
        <StyledIconButton onClick={hideSearch}>
          <Clear />
        </StyledIconButton>
      </>
    </Grow>
  );
};

export default CustomSearchRender;
