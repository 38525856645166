import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./styles/App.scss";
import Sidebar from "./components/sidebar/Sidebar";
import sidebarBg from "./assets/sidebar_bg.jpg";
import Users from "./pages/Users";
import Inspections from "./pages/Inspections";
import { FaBars } from "react-icons/fa";

const App = () => {
  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = (value: boolean) => {
    setToggled(value);
  };

  return (
    <div className="app">
      <Router>
        <Sidebar
          sidebarBg={sidebarBg}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
        />
        <main>
          <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
            <FaBars />
          </div>
          <Switch>
            <Route path="/users" exact component={Users} />
            <Route path="/inspections" exact component={Inspections} />
            <Route component={() => <h1>Not Found</h1>} />
          </Switch>
        </main>
      </Router>
    </div>
  );
};

export default App;
