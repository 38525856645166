import styled from "styled-components";

export const Toggle = styled.div`
  cursor: pointer;
  margin: 24px;
  margin-right: 28px;

  span {
    position: relative;
    width: 27px;
    height: 3px;
    background: #ffffff;
    display: block;
    margin-bottom: 7px;
    transition: 0.5s;
    background-color: #adadad;
  }

  &:hover span {
    background-color: tomato;
  }

  span:nth-child(1) {
    transform-origin: left;
  }

  span:nth-child(2) {
    transform-origin: center;
  }

  span:nth-child(3) {
    transform-origin: left;
    margin-bottom: 0;
  }

  &.active span:nth-child(1) {
    transform: rotate(45deg);
    left: 2px;
  }

  &.active span:nth-child(2) {
    transform: rotate(315deg);
    right: 2px;
  }

  &.active span:nth-child(3) {
    transform: scaleX(0);
  }
`;

export const Header = styled.div`
  margin-left: 2px;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const MenuText = styled.span`
  font-size: 17px;
  margin-left: 10px;
`;
