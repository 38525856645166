import React from "react";
import BuildingDetailsTable from "../components/inspection_tables/building_details";

const Inspections = () => {
  return (
    <div>
      <h1>Inspections</h1>
      <BuildingDetailsTable />
    </div>
  );
};

export default Inspections;
