import { createMuiTheme, Theme } from "@material-ui/core";

export const DefaultMuiTheme = (): Theme =>
  (createMuiTheme as any)({
    overrides: {
      MUIDataTableToolbar: {
        icon: {
          "&:hover": {
            color: "red",
          },
        },
        iconActive: {
          color: "red",
        },
      },
      MUIDataTableToolbarSelect: {
        root: {
          backgroundColor: "#feebf2",
          boxShadow: "none",
          borderRadius: 0,
        },
        iconButton: {
          "&:hover": {
            color: "red",
          },
        },
      },
      MUIDataTableHeadCell: {
        root: {
          fontWeight: "bold",
          fontSize: "1rem",

          "&:hover": {
            color: "red",
          },
        },
        sortLabelRoot: {
          height: "25px",
        },
        sortActive: {
          color: "red",
        },
      },
      MUIDataTableFilter: {
        root: {
          maxWidth: "400px",
        },
        title: {
          marginLeft: "0px",
          fontSize: "15px",
        },
        resetLink: {
          fontSize: "15px",
          color: "red",
        },
      },
      MUIDataTableJumpToPage: {
        root: {
          "@media screen and (max-width: 761px)": {
            padding: 0,
          },
        },
      },
      MUIDataTablePagination: {
        toolbar: {
          "@media screen and (max-width: 761px)": {
            marginTop: "-15px",
            padding: 0,
          },
        },
        navContainer: {
          "@media screen and (max-width: 761px)": {
            display: "grid",
          },
        },
      },
      MUIDataTableBodyCell: {
        root: {
          "@media screen and (max-width: 959px)": {
            width: "100% !important",
          },
        },
      },
    },
  });

  export const VerticalMuiTheme = (): Theme =>
  (createMuiTheme as any)({
    overrides: {
      MUIDataTableBodyCell: {
        simpleHeader: {
            display: 'inline-block',
            fontWeight: 'bold',
            width: '100%',
            boxSizing: 'border-box',
        },
        simpleCell: {
            display: 'inline-block',
            width: '100%',
            boxSizing: 'border-box',
        },
        stackedHeader: {
          verticalAlign: 'top',
          fontWeight: 'bold',
          paddingRight: 30,
          "@media screen and (max-width: 959px)": {
            paddingRight: 0,
          },
        },
        stackedCommon: {
            display: 'inline-block',
            fontSize: '16px',
            height: 'auto',
            width: 'calc(50%)',
            boxSizing: 'border-box',
            '&:last-child': {
              borderBottom: 'none',
            },
            '&:nth-last-child(2)': {
              borderBottom: 'none',
            },
        },
        stackedParent: {
            display: 'inline-block',
            fontSize: '16px',
            height: 'auto',
            width: 'calc(100%)',
            boxSizing: 'border-box',
        },
      },
      MUIDataTableBodyRow: {
        responsiveStacked: {
            borderTop: 'solid 2px rgba(0, 0, 0, 0.15)',
            borderBottom: 'solid 2px rgba(0, 0, 0, 0.15)',
            padding: 0,
            margin: 0,
        },
      },
      MUIDataTableHead: {
        main: {
          display: "none"
        },
      },
      MUIDataTableFooter: {
        root: {
          display: "none !important"
        }
      }
    },
  }, DefaultMuiTheme());
