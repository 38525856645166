import { MUIDataTableColumnOptions, MUIDataTableOptions } from "mui-datatables";

export interface ISelectables {
  title: string;
  value: string;
  note: string;
  snapshot: string;
}

export const DefaultDetailedTableOptions: MUIDataTableOptions = {
  sort: false,
  search: false,
  filter: false,
  selectableRows: "none",
};

export const DefaultColumnOptions: MUIDataTableColumnOptions = {
  display: false,
  searchable: false,
  viewColumns: false,
  filter: false,
};
