import React, { useEffect, useState } from "react";
import { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables";
import Axios from "axios";
import DataTable from "../../../../data_table/DataTable";
import { DefaultOptions } from "../../../../data_table/DefaultOptions";
import DefaultComponents from "../../../../data_table/CustomComponents";
import DetailedFireSprinklerMonthlyTable from "./Detailed";
import { IBuildingDetails } from "../../../building_details";
import FilterAutoComplete from "../../../../data_table/FilterAutoComplete";
import { ISelectables, DefaultColumnOptions } from "../../Commons";

interface IProps {
  buildingDetails: IBuildingDetails;
}

export interface IRow {
  id: string;
  date: string;
  selectables: ISelectables[];
  selectablesObj: { value: string };
}

const FireSprinklerMonthlyTable: React.FC<IProps> = ({ buildingDetails }) => {
  const [data, setData] = useState<IRow[]>([]);

  useEffect(() => {
    const params = {
      Id: buildingDetails.id,
    };

    Axios.post(`/DataAccess/Inspections/GetMonthlyFireSprinkler.php`, params)
      .then((response) => {
        setData(response.data.monthlyFireSprinklerDetails);
      })
      .catch((err: Error) => {
        alert(err);
      });
  }, [buildingDetails]);

  const options: MUIDataTableOptions = {
    expandableRows: true,
    renderExpandableRow: (_, rowMeta) => {
      return (
        <DetailedFireSprinklerMonthlyTable
          buildingDetails={buildingDetails}
          data={data[rowMeta.dataIndex]}
        />
      );
    },
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: "id",
      label: "Report ID",
      options: {
        filterType: "custom",
        filterList: [],
        customFilterListOptions: {
          render: (v: string[]) => `ID: ${v.join(", ")}`,
          update: (filterList: any[], _: number, index: number) => {
            filterList[index].splice(0, filterList[index].length);
            return filterList;
          },
        },
        filterOptions: {
          logic: (prop, filterValue) => {
            if (filterValue.length) return !filterValue.includes(prop);
            return false;
          },
          display: (filterList, onChange, index, column) => {
            let filterItems = data.map((row) => row.id);

            return (
              <FilterAutoComplete
                title={column.label}
                itemCount={filterItems.length}
                filterOptions={filterItems}
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
              />
            );
          },
          fullWidth: true,
        },
      },
    },
    {
      name: "date",
      label: "Added Date",
      options: {
        filterType: "custom",
        filterList: [],
        customFilterListOptions: {
          render: (v: string[]) => `Added Date: ${v.join(", ")}`,
          update: (filterList: any[], _: number, index: number) => {
            filterList[index].splice(0, filterList[index].length);
            return filterList;
          },
        },
        filterOptions: {
          logic: (prop, filterValue) => {
            if (filterValue.length) return !filterValue.includes(prop);
            return false;
          },
          display: (filterList, onChange, index, column) => {
            let filterItems = [
              ...Array.from(new Set(data.map((row) => row.date))),
            ];

            return (
              <FilterAutoComplete
                title={column.label}
                itemCount={filterItems.length}
                filterOptions={filterItems}
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
              />
            );
          },
          fullWidth: true,
        },
      },
    },
  ];

  data[0]?.selectables.forEach((element, index) => {
    columns.splice(columns.length, 0, {
      name: `selectablesObj.${index}`,
      label: element.title,
      options: DefaultColumnOptions,
    });
  });

  const handleDeleteSelected = (deletedRowIds: any[]) => {
    setData(
      data.filter((row) => !deletedRowIds.includes(row.id)).map((row) => row)
    );
  };

  return (
    <div style={{ minWidth: 200 }}>
      <DataTable
        title="Monthly Inspections - Fire Sprinkler"
        columns={columns}
        data={data}
        options={{
          ...DefaultOptions({
            title: `Monthly Inspections - Fire Sprinkler (Building - ${buildingDetails.name})`,
            rowCount: data.length,
            onDelete: handleDeleteSelected,
          }),
          ...options,
        }}
        components={DefaultComponents}
      />
    </div>
  );
};

export default FireSprinklerMonthlyTable;
