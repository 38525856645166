import React from "react";
import { MUIDataTableColumnDef } from "mui-datatables";
import DataTable from "../../../../data_table/DataTable";
import { DefaultOptions } from "../../../../data_table/DefaultOptions";
import DefaultComponents from "../../../../data_table/CustomComponents";
import { VerticalMuiTheme } from "../../../../data_table/Themes";
import { IRow } from "./index";
import { TableCell, TableRow } from "@material-ui/core";
import { IBuildingDetails } from "../../../building_details";
import { DefaultDetailedTableOptions } from "../../Commons";

interface IProps {
  buildingDetails: IBuildingDetails;
  data: IRow;
}

const DetailedStandpipeMonthlyTable: React.FC<IProps> = ({
  buildingDetails,
  data,
}) => {
  const reportTitle = `Monthly Standpipe Inspection - Report ID - ${data.id}`;

  const columns: MUIDataTableColumnDef[] = [
    {
      name: `🔴 Building Details: ${reportTitle}`,
      options: {
        download: false,
      },
    },
    {
      name: "name",
      label: "Name:",
    },
    {
      name: "address",
      label: "Address:",
    },
    {
      name: "person",
      label: "Person:",
    },
    {
      name: "phone",
      label: "Phone:",
    },
    {
      name: "email",
      label: "Email:",
    },
    {
      name: "date",
      label: "Date:",
    },
    {
      name: `🔴 Report Details: ${reportTitle}`,
      options: {
        download: false,
      },
    },
  ];

  data.selectables.forEach((element, index) => {
    let color = "orange";
    if (element.value === "YES") color = "green";
    else if (element.value === "NO") color = "red";

    columns.splice(columns.length, 0, {
      name: `selectablesObj.${index}`,
      label: element.title,
      options: {
        customBodyRenderLite: () => {
          return (
            <>
              {<span style={{ color: color }}>{element.value}</span>}
              <br />
              {element.note && (
                <>
                  Note: {element.note}
                  <br />
                </>
              )}
              {element.snapshot && (
                <>
                  Snapshot:
                  <br />
                  <img
                    src={`https://app.rightaction.lk/Uploads/Snapshots/${element.snapshot}`}
                    alt=""
                    width="200px"
                  />
                </>
              )}
            </>
          );
        },
      },
    });
  });

  return (
    <TableRow>
      <TableCell
        style={{ backgroundColor: "whiteSmoke", padding: 10, minWidth: 200 }}
        colSpan={3}
      >
        <DataTable
          title={reportTitle}
          columns={columns}
          data={[{ ...data, ...buildingDetails }]}
          options={{
            ...DefaultOptions({
              title: reportTitle,
            }),
            ...DefaultDetailedTableOptions,
          }}
          components={DefaultComponents}
          theme={VerticalMuiTheme()}
        />
      </TableCell>
    </TableRow>
  );
};

export default DetailedStandpipeMonthlyTable;
