import React from "react";
import { CustomTooltip } from "./CustomComponents";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import { Delete } from "@material-ui/icons";

const StyledIconButton = styled(IconButton)`
  &:hover {
    color: red;
  }
`;

interface IProps {
  selectedRows: {
    data: {
      index: number;
      dataIndex: number;
    }[];
    lookup: {
      [key: number]: boolean;
    };
  };
  displayData: {
    data: any[];
    dataIndex: number;
  }[];
  setSelectedRows: (rows: number[]) => void;
  onDelete?: (deletedRowIds: any[]) => void;
}

const CustomToolbarSelect: React.FC<IProps> = ({
  selectedRows,
  displayData,
  setSelectedRows,
  onDelete,
}) => {
  const handleClickInverseSelection = () => {
    const nextSelectedRows = displayData.reduce(
      (nextSelectedRows: number[], _, index) => {
        if (
          !selectedRows.data.find((selectedRow) => selectedRow.index === index)
        ) {
          nextSelectedRows.push(index);
        }

        return nextSelectedRows;
      },
      []
    );

    setSelectedRows(nextSelectedRows);
  };

  const handleClickDeselectAll = () => {
    setSelectedRows([]);
  };

  const handleClickDeleteSelected = () => {
    if (onDelete) {
      let deletedData = displayData.filter((data) =>
        selectedRows.data.map((row) => row.dataIndex).includes(data.dataIndex)
      );
      onDelete(deletedData.map((data) => data.data[0]));
      setSelectedRows([]);
    }
  };

  return (
    <div style={{ marginRight: "24px" }}>
      <CustomTooltip title={"Deselect All"}>
        <StyledIconButton onClick={handleClickDeselectAll}>
          <IndeterminateCheckBoxIcon />
        </StyledIconButton>
      </CustomTooltip>
      <CustomTooltip title={"Inverse Selection"}>
        <StyledIconButton onClick={handleClickInverseSelection}>
          <CompareArrowsIcon style={{ transform: "rotate(90deg)" }} />
        </StyledIconButton>
      </CustomTooltip>
      <CustomTooltip title={"Delete Selected"}>
        <StyledIconButton onClick={handleClickDeleteSelected}>
          <Delete />
        </StyledIconButton>
      </CustomTooltip>
    </div>
  );
};

export default CustomToolbarSelect;
