import React from "react";
import { Checkbox, Tooltip, Zoom } from "@material-ui/core";
import { MUIDataTableProps, TableFilterList } from "mui-datatables";
import CustomChip from "./CustomChip";

export const CustomFilterList = (props: any) => {
  return <TableFilterList {...props} ItemComponent={CustomChip} />;
};

export const CustomCheckbox = (props: any) => {
  let newProps = Object.assign({}, props);
  newProps.color = "secondary";

  return <Checkbox {...newProps} />;
};

export const CustomTooltip = (props: any) => {
  return (
    <Tooltip title={props.title} TransitionComponent={Zoom}>
      {props.children}
    </Tooltip>
  );
};

// For the components with no types
interface IComponents {
  Checkbox?: React.ReactNode;
  ExpandButton?: React.ReactNode;
}

const DefaultComponents: MUIDataTableProps["components"] & IComponents = {
  TableFilterList: CustomFilterList,
  Tooltip: CustomTooltip,
  Checkbox: CustomCheckbox,
};

export default DefaultComponents;
