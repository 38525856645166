import React from "react";

const Users = () => {
  return (
    <div>
      <h1>Users</h1>
    </div>
  );
};

export default Users;
