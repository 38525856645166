import React from "react";
import { MUIDataTableColumnDef } from "mui-datatables";
import DataTable from "../../../../data_table/DataTable";
import { DefaultOptions } from "../../../../data_table/DefaultOptions";
import DefaultComponents from "../../../../data_table/CustomComponents";
import { VerticalMuiTheme } from "../../../../data_table/Themes";
import { IRow } from "./index";
import { TableCell, TableRow } from "@material-ui/core";
import { IBuildingDetails } from "../../../building_details";
import { DefaultDetailedTableOptions } from "../../Commons";

interface IProps {
  buildingDetails: IBuildingDetails;
  data: IRow;
}

const DetailedFireDoorMonthlyTable: React.FC<IProps> = ({
  buildingDetails,
  data,
}) => {
  const reportTitle = `Monthly Fire Door Inspection - Report ID - ${data.id}`;

  const columns: MUIDataTableColumnDef[] = [
    {
      name: `🔴 Building Details: ${reportTitle}`,
      options: {
        download: false,
      },
    },
    {
      name: "name",
      label: "Name:",
    },
    {
      name: "address",
      label: "Address:",
    },
    {
      name: "person",
      label: "Person:",
    },
    {
      name: "phone",
      label: "Phone:",
    },
    {
      name: "email",
      label: "Email:",
    },
    {
      name: "date",
      label: "Date:",
    },
    {
      name: `🔴 Report Details: ${reportTitle}`,
      options: {
        download: false,
      },
    },
    {
      name: "ratingInstalled",
      label: "Door Rating (Installed):",
    },
    {
      name: "ratingSpecified",
      label: "Door Rating (Specified):",
    },
    {
      name: "floor",
      label: "Floor:",
    },
    {
      name: "location",
      label: "Location:",
    },
    {
      name: "doorMaterial",
      label: "Door Material:",
    },
    {
      name: "frameMaterial",
      label: "Frame Material:",
    },
    {
      name: "innerDimen",
      label: "Inner Frame Dimentions:",
    },
    {
      name: "outerDimen",
      label: "Outer Frame Dimentions:",
    },
    {
      name: "doorSet",
      label: "Door Set:",
    },
    {
      name: "doorClosures",
      label: "Door Closures:",
    },
  ];

  return (
    <TableRow>
      <TableCell
        style={{ backgroundColor: "whiteSmoke", padding: 10, minWidth: 200 }}
        colSpan={3}
      >
        <DataTable
          title={reportTitle}
          columns={columns}
          data={[{ ...data, ...buildingDetails }]}
          options={{
            ...DefaultOptions({
              title: reportTitle,
            }),
            ...DefaultDetailedTableOptions,
          }}
          components={DefaultComponents}
          theme={VerticalMuiTheme()}
        />
      </TableCell>
    </TableRow>
  );
};

export default DetailedFireDoorMonthlyTable;
