import React, { useEffect, useState } from "react";
import { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables";
import Axios from "axios";
import DataTable from "../../../../data_table/DataTable";
import { DefaultOptions } from "../../../../data_table/DefaultOptions";
import DefaultComponents from "../../../../data_table/CustomComponents";
import DetailedFireExtiMonthlyTable from "./Detailed";
import { IBuildingDetails } from "../../../building_details";
import FilterAutoComplete from "../../../../data_table/FilterAutoComplete";
import { ISelectables, DefaultColumnOptions } from "../../Commons";

interface IProps {
  buildingDetails: IBuildingDetails;
}

export interface IRow {
  id: string;
  date: string;
  tagId: string;
  deviceType: string;
  place: string;
  extiNum: string;
  manufacturer: string;
  model: string;
  manufDate: string;
  extiType: string;
  extiWeight: string;
  inspDate: string;
  maintananceDate: string;
  hydrostaticallyDate: string;
  inspDueDate: string;
  selectables: ISelectables[];
  selectablesObj: { value: string };
}

const FireExtiMonthlyTable: React.FC<IProps> = ({ buildingDetails }) => {
  const [data, setData] = useState<IRow[]>([]);

  useEffect(() => {
    const params = {
      Id: buildingDetails.id,
    };

    Axios.post(`/DataAccess/Inspections/GetMonthlyFireExti.php`, params)
      .then((response) => {
        setData(response.data.monthlyFireExtiDetails);
      })
      .catch((err: Error) => {
        alert(err);
      });
  }, [buildingDetails]);

  const options: MUIDataTableOptions = {
    expandableRows: true,
    renderExpandableRow: (_, rowMeta) => {
      return (
        <DetailedFireExtiMonthlyTable
          buildingDetails={buildingDetails}
          data={data[rowMeta.dataIndex]}
        />
      );
    },
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: "id",
      label: "Report ID",
      options: {
        filterType: "custom",
        filterList: [],
        customFilterListOptions: {
          render: (v: string[]) => `ID: ${v.join(", ")}`,
          update: (filterList: any[], _: number, index: number) => {
            filterList[index].splice(0, filterList[index].length);
            return filterList;
          },
        },
        filterOptions: {
          logic: (prop, filterValue) => {
            if (filterValue.length) return !filterValue.includes(prop);
            return false;
          },
          display: (filterList, onChange, index, column) => {
            let filterItems = data.map((row) => row.id);

            return (
              <FilterAutoComplete
                title={column.label}
                itemCount={filterItems.length}
                filterOptions={filterItems}
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
              />
            );
          },
          fullWidth: true,
        },
      },
    },
    {
      name: "date",
      label: "Added Date",
      options: {
        filterType: "custom",
        filterList: [],
        customFilterListOptions: {
          render: (v: string[]) => `Added Date: ${v.join(", ")}`,
          update: (filterList: any[], _: number, index: number) => {
            filterList[index].splice(0, filterList[index].length);
            return filterList;
          },
        },
        filterOptions: {
          logic: (prop, filterValue) => {
            if (filterValue.length) return !filterValue.includes(prop);
            return false;
          },
          display: (filterList, onChange, index, column) => {
            let filterItems = [
              ...Array.from(new Set(data.map((row) => row.date))),
            ];

            return (
              <FilterAutoComplete
                title={column.label}
                itemCount={filterItems.length}
                filterOptions={filterItems}
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
              />
            );
          },
          fullWidth: true,
        },
      },
    },
    {
      name: "tagId",
      label: "Tag ID:",
      options: DefaultColumnOptions,
    },
    {
      name: "deviceType",
      label: "DeviceType:",
      options: DefaultColumnOptions,
    },
    {
      name: "place",
      label: "Location in Designated Place:",
      options: DefaultColumnOptions,
    },
    {
      name: "extiNum",
      label: "Extinguisher Number:",
      options: DefaultColumnOptions,
    },
    {
      name: "manufacturer",
      label: "Manufacturer:",
      options: DefaultColumnOptions,
    },
    {
      name: "model",
      label: "Model:",
      options: DefaultColumnOptions,
    },
    {
      name: "manufDate",
      label: "Date of Extinguisher Manufacture:",
      options: DefaultColumnOptions,
    },
    {
      name: "extiType",
      label: "Extinguisher Type:",
      options: DefaultColumnOptions,
    },
    {
      name: "extiWeight",
      label: "Extinguisher Weight:",
      options: DefaultColumnOptions,
    },
    {
      name: "inspDate",
      label: "Date of Last Inspection:",
      options: DefaultColumnOptions,
    },
    {
      name: "maintananceDate",
      label: "Date of Last Maintenance:",
      options: DefaultColumnOptions,
    },
    {
      name: "hydrostaticallyDate",
      label: "Date the Extinguisher was Last Hydrostatically Tested:",
      options: DefaultColumnOptions,
    },
    {
      name: "inspDueDate",
      label: "Next Inspection Due Date:",
      options: DefaultColumnOptions,
    },
  ];

  data[0]?.selectables.forEach((element, index) => {
    columns.splice(columns.length - 1, 0, {
      name: `selectablesObj.${index}`,
      label: element.title,
      options: DefaultColumnOptions,
    });
  });

  const handleDeleteSelected = (deletedRowIds: any[]) => {
    setData(
      data.filter((row) => !deletedRowIds.includes(row.id)).map((row) => row)
    );
  };

  return (
    <div style={{ minWidth: 200 }}>
      <DataTable
        title="Monthly Inspections - Fire Extinguisher"
        columns={columns}
        data={data}
        options={{
          ...DefaultOptions({
            title: `Monthly Inspections - Fire Extinguisher (Building - ${buildingDetails.name})`,
            rowCount: data.length,
            onDelete: handleDeleteSelected,
          }),
          ...options,
        }}
        components={DefaultComponents}
      />
    </div>
  );
};

export default FireExtiMonthlyTable;
