import React, { useEffect, useState } from "react";
import { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables";
import Axios from "axios";
import DataTable from "../../../../data_table/DataTable";
import { DefaultOptions } from "../../../../data_table/DefaultOptions";
import DefaultComponents from "../../../../data_table/CustomComponents";
import DetailedFireDoorMonthlyTable from "./Detailed";
import { IBuildingDetails } from "../../../building_details";
import FilterAutoComplete from "../../../../data_table/FilterAutoComplete";
import { DefaultColumnOptions } from "../../Commons";

interface IProps {
  buildingDetails: IBuildingDetails;
}

export interface IRow {
  id: string;
  date: string;
  ratingInstalled: string;
  ratingSpecified: string;
  floor: string;
  location: string;
  doorMaterial: string;
  frameMaterial: string;
  innerDimen: string;
  outerDimen: string;
  doorSet: string;
  doorClosures: string;
}

const FireDoorMonthlyTable: React.FC<IProps> = ({ buildingDetails }) => {
  const [data, setData] = useState<IRow[]>([]);

  useEffect(() => {
    const params = {
      Id: buildingDetails.id,
    };

    Axios.post(`/DataAccess/Inspections/GetMonthlyFireDoor.php`, params)
      .then((response) => {
        setData(response.data.monthlyFireDoorDetails);
      })
      .catch((err: Error) => {
        alert(err);
      });
  }, [buildingDetails]);

  const options: MUIDataTableOptions = {
    expandableRows: true,
    renderExpandableRow: (_, rowMeta) => {
      return (
        <DetailedFireDoorMonthlyTable
          buildingDetails={buildingDetails}
          data={data[rowMeta.dataIndex]}
        />
      );
    },
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: "id",
      label: "Report ID",
      options: {
        filterType: "custom",
        filterList: [],
        customFilterListOptions: {
          render: (v: string[]) => `ID: ${v.join(", ")}`,
          update: (filterList: any[], _: number, index: number) => {
            filterList[index].splice(0, filterList[index].length);
            return filterList;
          },
        },
        filterOptions: {
          logic: (prop, filterValue) => {
            if (filterValue.length) return !filterValue.includes(prop);
            return false;
          },
          display: (filterList, onChange, index, column) => {
            let filterItems = data.map((row) => row.id);

            return (
              <FilterAutoComplete
                title={column.label}
                itemCount={filterItems.length}
                filterOptions={filterItems}
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
              />
            );
          },
          fullWidth: true,
        },
      },
    },
    {
      name: "date",
      label: "Added Date",
      options: {
        filterType: "custom",
        filterList: [],
        customFilterListOptions: {
          render: (v: string[]) => `Added Date: ${v.join(", ")}`,
          update: (filterList: any[], _: number, index: number) => {
            filterList[index].splice(0, filterList[index].length);
            return filterList;
          },
        },
        filterOptions: {
          logic: (prop, filterValue) => {
            if (filterValue.length) return !filterValue.includes(prop);
            return false;
          },
          display: (filterList, onChange, index, column) => {
            let filterItems = [
              ...Array.from(new Set(data.map((row) => row.date))),
            ];

            return (
              <FilterAutoComplete
                title={column.label}
                itemCount={filterItems.length}
                filterOptions={filterItems}
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
              />
            );
          },
          fullWidth: true,
        },
      },
    },
    {
      name: "ratingInstalled",
      label: "Door Rating (Installed):",
      options: DefaultColumnOptions,
    },
    {
      name: "ratingSpecified",
      label: "Door Rating (Specified):",
      options: DefaultColumnOptions,
    },
    {
      name: "floor",
      label: "Floor:",
      options: DefaultColumnOptions,
    },
    {
      name: "location",
      label: "Location:",
      options: DefaultColumnOptions,
    },
    {
      name: "doorMaterial",
      label: "Door Material:",
      options: DefaultColumnOptions,
    },
    {
      name: "frameMaterial",
      label: "Frame Material:",
      options: DefaultColumnOptions,
    },
    {
      name: "innerDimen",
      label: "Inner Frame Dimentions:",
      options: DefaultColumnOptions,
    },
    {
      name: "outerDimen",
      label: "Outer Frame Dimentions:",
      options: DefaultColumnOptions,
    },
    {
      name: "doorSet",
      label: "Door Set:",
      options: DefaultColumnOptions,
    },
    {
      name: "doorClosures",
      label: "Door Closures:",
      options: DefaultColumnOptions,
    },
  ];

  const handleDeleteSelected = (deletedRowIds: any[]) => {
    setData(
      data.filter((row) => !deletedRowIds.includes(row.id)).map((row) => row)
    );
  };

  return (
    <div style={{ minWidth: 200 }}>
      <DataTable
        title="Monthly Inspections - Fire Door"
        columns={columns}
        data={data}
        options={{
          ...DefaultOptions({
            title: `Monthly Inspections - Fire Door (Building - ${buildingDetails.name})`,
            rowCount: data.length,
            onDelete: handleDeleteSelected,
          }),
          ...options,
        }}
        components={DefaultComponents}
      />
    </div>
  );
};

export default FireDoorMonthlyTable;
