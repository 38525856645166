import React from "react";
import { MUIDataTableOptions } from "mui-datatables";
import CustomSearchRender from "./CustomSerach";
import CustomToolbarSelect from "./CustomToolbarSelect";

interface IProps {
  title: string;
  rowCount?: number;
  onDelete?: (deletedRowIds: any[]) => void;
}
const rowsPerPage = [5, 10, 15];

// For the options with no types
interface IOptions {
  jumpToPage?: boolean;
}

export const DefaultOptions = ({
  title,
  rowCount,
  onDelete,
}: IProps): MUIDataTableOptions & IOptions => ({
  customSearchRender: (searchText, handleSearch, hideSearch, options) => {
    return (
      <CustomSearchRender
        searchText={searchText}
        handleSearch={handleSearch}
        hideSearch={hideSearch}
        options={options}
        rowCount={rowCount}
      />
    );
  },
  downloadOptions: {
    filename: `${title}.csv`,
  },
  filter: true,
  filterType: "textField",
  sort: true,
  draggableColumns: {
    enabled: true,
  },
  enableNestedDataAccess: ".",
  rowsPerPage: rowsPerPage[0],
  rowsPerPageOptions: rowsPerPage,
  fixedSelectColumn: false,
  setTableProps: () => {
    return {
      padding: "default",
      size: "small",
    };
  },
  expandableRowsHeader: false,
  expandableRowsOnClick: true,
  jumpToPage: true,
  customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
    return (
      <CustomToolbarSelect
        selectedRows={selectedRows}
        displayData={displayData}
        setSelectedRows={setSelectedRows}
        onDelete={onDelete}
      />
    );
  },
});
