import React, { useEffect, useState } from "react";
import { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables";
import Axios from "axios";
import DataTable from "../../data_table/DataTable";
import { DefaultOptions } from "../../data_table/DefaultOptions";
import DefaultComponents from "../../data_table/CustomComponents";
import FilterAutoComplete from "../../data_table/FilterAutoComplete";
import ExpandableRow from "./ExpandableRow";

export interface IBuildingDetails {
  id: number;
  name: string;
  address: string;
  person: string;
  phone: string;
  email: string;
  date: string;
}

const BuildingDetailsTable: React.FC = () => {
  const [data, setData] = useState<IBuildingDetails[]>([]);

  useEffect(() => {
    Axios.get(`/DataAccess/Inspections/GetAllBuildingDetails.php`)
      .then((response) => {
        setData(response.data.buildingDetails);
      })
      .catch((err: Error) => {
        alert(err);
      });
  }, []);

  const columns: MUIDataTableColumnDef[] = [
    {
      name: "id",
      options: {
        display: false,
        searchable: false,
        download: false,
        viewColumns: false,
        filter: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filterType: "custom",
        filterList: [],
        customFilterListOptions: {
          render: (v: string[]) => `Name: ${v.join(", ")}`,
          update: (filterList: any[], _: number, index: number) => {
            filterList[index].splice(0, filterList[index].length);
            return filterList;
          },
        },
        filterOptions: {
          logic: (prop, filterValue) => {
            if (filterValue.length) return !filterValue.includes(prop);
            return false;
          },
          display: (filterList, onChange, index, column) => {
            let filterItems = [
              ...Array.from(new Set(data.map((row) => row.name))),
            ];

            return (
              <FilterAutoComplete
                title={column.label}
                itemCount={filterItems.length}
                filterOptions={filterItems}
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
              />
            );
          },
          fullWidth: true,
        },
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filterType: "custom",
        filterList: [],
        customFilterListOptions: {
          render: (v: string[]) => `Address: ${v.join(", ")}`,
          update: (filterList: any[], _: number, index: number) => {
            filterList[index].splice(0, filterList[index].length);
            return filterList;
          },
        },
        filterOptions: {
          logic: (prop, filterValue) => {
            if (filterValue.length) return !filterValue.includes(prop);
            return false;
          },
          display: (filterList, onChange, index, column) => {
            let filterItems = [
              ...Array.from(new Set(data.map((row) => row.address))),
            ];

            return (
              <FilterAutoComplete
                title={column.label}
                itemCount={filterItems.length}
                filterOptions={filterItems}
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
              />
            );
          },
          fullWidth: true,
        },
      },
    },
    {
      name: "person",
      label: "Person",
      options: {
        filterType: "custom",
        filterList: [],
        customFilterListOptions: {
          render: (v: string[]) => `Person: ${v.join(", ")}`,
          update: (filterList: any[], _: number, index: number) => {
            filterList[index].splice(0, filterList[index].length);
            return filterList;
          },
        },
        filterOptions: {
          logic: (prop, filterValue) => {
            if (filterValue.length) return !filterValue.includes(prop);
            return false;
          },
          display: (filterList, onChange, index, column) => {
            let filterItems = [
              ...Array.from(new Set(data.map((row) => row.person))),
            ];

            return (
              <FilterAutoComplete
                title={column.label}
                itemCount={filterItems.length}
                filterOptions={filterItems}
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
              />
            );
          },
          fullWidth: true,
        },
      },
    },
    {
      name: "phone",
      label: "Phone",
      options: {
        filterType: "custom",
        filterList: [],
        customFilterListOptions: {
          render: (v: string[]) => `Phone: ${v.join(", ")}`,
          update: (filterList: any[], _: number, index: number) => {
            filterList[index].splice(0, filterList[index].length);
            return filterList;
          },
        },
        filterOptions: {
          logic: (prop, filterValue) => {
            if (filterValue.length) return !filterValue.includes(prop);
            return false;
          },
          display: (filterList, onChange, index, column) => {
            let filterItems = [
              ...Array.from(new Set(data.map((row) => row.phone))),
            ];

            return (
              <FilterAutoComplete
                title={column.label}
                itemCount={filterItems.length}
                filterOptions={filterItems}
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
              />
            );
          },
          fullWidth: true,
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filterType: "custom",
        filterList: [],
        customFilterListOptions: {
          render: (v: string[]) => `Email: ${v.join(", ")}`,
          update: (filterList: any[], _: number, index: number) => {
            filterList[index].splice(0, filterList[index].length);
            return filterList;
          },
        },
        filterOptions: {
          logic: (prop, filterValue) => {
            if (filterValue.length) return !filterValue.includes(prop);
            return false;
          },
          display: (filterList, onChange, index, column) => {
            let filterItems = [
              ...Array.from(new Set(data.map((row) => row.email))),
            ];

            return (
              <FilterAutoComplete
                title={column.label}
                itemCount={filterItems.length}
                filterOptions={filterItems}
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
              />
            );
          },
          fullWidth: true,
        },
      },
    },
    {
      name: "date",
      label: "Added Date",
      options: {
        filterType: "custom",
        filterList: [],
        customFilterListOptions: {
          render: (v: string[]) => `Added Date: ${v.join(", ")}`,
          update: (filterList: any[], _: number, index: number) => {
            filterList[index].splice(0, filterList[index].length);
            return filterList;
          },
        },
        filterOptions: {
          logic: (prop, filterValue) => {
            if (filterValue.length) return !filterValue.includes(prop);
            return false;
          },
          display: (filterList, onChange, index, column) => {
            let filterItems = [
              ...Array.from(new Set(data.map((row) => row.date))),
            ];

            return (
              <FilterAutoComplete
                title={column.label}
                itemCount={filterItems.length}
                filterOptions={filterItems}
                filterList={filterList}
                onChange={onChange}
                index={index}
                column={column}
              />
            );
          },
          fullWidth: true,
        },
      },
    },
  ];

  const handleDeleteSelected = (deletedRowIds: any[]) => {
    setData(
      data.filter((row) => !deletedRowIds.includes(row.id)).map((row) => row)
    );
  };

  const options: MUIDataTableOptions = {
    expandableRows: true,
    renderExpandableRow: (_, rowMeta) => {
      return <ExpandableRow rowData={data[rowMeta.dataIndex]} />;
    },
  };

  return (
    <div style={{ minWidth: 200 }}>
      <DataTable
        title="Building Details"
        columns={columns}
        data={data}
        options={{
          ...DefaultOptions({
            title: "Building Details",
            rowCount: data.length,
            onDelete: handleDeleteSelected,
          }),
          ...options,
        }}
        components={DefaultComponents}
      />
    </div>
  );
};

export default BuildingDetailsTable;
