import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import Collapsible from "react-collapsible";
import "./ExpandableRow.styles.scss";
import FireExtiMonthlyTable from "../fire_protection_system/fire_exti/monthly/";
import { IBuildingDetails } from ".";
import FireDoorMonthlyTable from "../fire_protection_system/fire_door/monthly";
import FireSprinklerMonthlyTable from "../fire_protection_system/fire_sprinkler/monthly";
import StandpipeMonthlyTable from "../fire_protection_system/standpipe/monthly";

interface IProps {
  rowData: IBuildingDetails;
}

const ExpandableRow: React.FC<IProps> = ({ rowData }) => {
  const colSpan = Object.keys(rowData).length;

  return (
    <TableRow>
      <TableCell style={{ backgroundColor: "whiteSmoke" }} colSpan={colSpan}>
        <Collapsible
          transitionTime={150}
          trigger="Building Fire Prevention"
        ></Collapsible>
        <Collapsible transitionTime={150} trigger="Fire Protection System">
          <Collapsible transitionTime={150} trigger="Fire Extinguisher">
            <Collapsible
              transitionTime={150}
              trigger="Monthly Inspections"
              lazyRender
            >
              <FireExtiMonthlyTable buildingDetails={rowData} />
            </Collapsible>
            <Collapsible
              transitionTime={150}
              trigger="Annual Inspections"
              lazyRender
            >
              // Annual
            </Collapsible>
            <Collapsible
              transitionTime={150}
              trigger="6-Year Hydrostatic Test"
              lazyRender
            >
              // 6-Year Hydrostatic
            </Collapsible>
          </Collapsible>
          <Collapsible transitionTime={150} trigger="Emergency Lighting">
            <Collapsible
              transitionTime={150}
              trigger="Monthly Inspections"
              lazyRender
            >
              // Monthly
            </Collapsible>
            <Collapsible
              transitionTime={150}
              trigger="Annual Inspections"
              lazyRender
            >
              // Annual
            </Collapsible>
            <Collapsible
              transitionTime={150}
              trigger="6-Year Hydrostatic Test"
              lazyRender
            >
              // 6-Year Hydrostatic
            </Collapsible>
          </Collapsible>
          <Collapsible transitionTime={150} trigger="Fire Alarm">
            <Collapsible
              transitionTime={150}
              trigger="Monthly Inspections"
              lazyRender
            >
              // Monthly
            </Collapsible>
            <Collapsible
              transitionTime={150}
              trigger="Annual Inspections"
              lazyRender
            >
              // Annual
            </Collapsible>
            <Collapsible
              transitionTime={150}
              trigger="6-Year Hydrostatic Test"
              lazyRender
            >
              // 6-Year Hydrostatic
            </Collapsible>
          </Collapsible>
          <Collapsible transitionTime={150} trigger="Hose Reel">
            <Collapsible
              transitionTime={150}
              trigger="Monthly Inspections"
              lazyRender
            >
              // Monthly
            </Collapsible>
            <Collapsible
              transitionTime={150}
              trigger="Annual Inspections"
              lazyRender
            >
              // Annual
            </Collapsible>
            <Collapsible
              transitionTime={150}
              trigger="6-Year Hydrostatic Test"
              lazyRender
            >
              // 6-Year Hydrostatic
            </Collapsible>
          </Collapsible>
          <Collapsible transitionTime={150} trigger="Fire Door">
            <Collapsible
              transitionTime={150}
              trigger="Monthly Inspections"
              lazyRender
            >
              <FireDoorMonthlyTable buildingDetails={rowData} />
            </Collapsible>
            <Collapsible
              transitionTime={150}
              trigger="Annual Inspections"
              lazyRender
            >
              // Annual
            </Collapsible>
            <Collapsible
              transitionTime={150}
              trigger="6-Year Hydrostatic Test"
              lazyRender
            >
              // 6-Year Hydrostatic
            </Collapsible>
          </Collapsible>
          <Collapsible transitionTime={150} trigger="Fire Detector">
            <Collapsible
              transitionTime={150}
              trigger="Monthly Inspections"
              lazyRender
            >
              // Monthly
            </Collapsible>
            <Collapsible
              transitionTime={150}
              trigger="Annual Inspections"
              lazyRender
            >
              // Annual
            </Collapsible>
            <Collapsible
              transitionTime={150}
              trigger="6-Year Hydrostatic Test"
              lazyRender
            >
              // 6-Year Hydrostatic
            </Collapsible>
          </Collapsible>
          <Collapsible transitionTime={150} trigger="Fire Sprinkler">
            <Collapsible
              transitionTime={150}
              trigger="Monthly Inspections"
              lazyRender
            >
              <FireSprinklerMonthlyTable buildingDetails={rowData} />
            </Collapsible>
            <Collapsible
              transitionTime={150}
              trigger="Annual Inspections"
              lazyRender
            >
              // Annual
            </Collapsible>
            <Collapsible
              transitionTime={150}
              trigger="6-Year Hydrostatic Test"
              lazyRender
            >
              // 6-Year Hydrostatic
            </Collapsible>
          </Collapsible>
          <Collapsible transitionTime={150} trigger="Fire Pump">
            <Collapsible
              transitionTime={150}
              trigger="Monthly Inspections"
              lazyRender
            >
              // Monthly
            </Collapsible>
            <Collapsible
              transitionTime={150}
              trigger="Annual Inspections"
              lazyRender
            >
              // Annual
            </Collapsible>
            <Collapsible
              transitionTime={150}
              trigger="6-Year Hydrostatic Test"
              lazyRender
            >
              // 6-Year Hydrostatic
            </Collapsible>
          </Collapsible>
          <Collapsible transitionTime={150} trigger="Electrical System">
            <Collapsible
              transitionTime={150}
              trigger="Monthly Inspections"
              lazyRender
            >
              // Monthly
            </Collapsible>
            <Collapsible
              transitionTime={150}
              trigger="Annual Inspections"
              lazyRender
            >
              // Annual
            </Collapsible>
            <Collapsible
              transitionTime={150}
              trigger="6-Year Hydrostatic Test"
              lazyRender
            >
              // 6-Year Hydrostatic
            </Collapsible>
          </Collapsible>
          <Collapsible transitionTime={150} trigger="Standpipe System">
            <Collapsible
              transitionTime={150}
              trigger="Monthly Inspections"
              lazyRender
            >
              <StandpipeMonthlyTable buildingDetails={rowData} />
            </Collapsible>
            <Collapsible
              transitionTime={150}
              trigger="Annual Inspections"
              lazyRender
            >
              // Annual
            </Collapsible>
            <Collapsible
              transitionTime={150}
              trigger="6-Year Hydrostatic Test"
              lazyRender
            >
              // 6-Year Hydrostatic
            </Collapsible>
          </Collapsible>
        </Collapsible>
        <Collapsible
          transitionTime={150}
          trigger="Life Safety & Safety Storage and Use of Hazardous Materials"
        ></Collapsible>
        <Collapsible
          transitionTime={150}
          trigger="Facilities & Processes"
        ></Collapsible>
      </TableCell>
    </TableRow>
  );
};

export default ExpandableRow;
