import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import { FaUsers, FaList, FaGithub } from "react-icons/fa";

import { Toggle, Header, MenuText } from "./Sidebar.styles";

interface Props {
  sidebarBg: string;
  toggled: boolean;
  handleToggleSidebar: (value: boolean) => void;
}

const Sidebar: React.FC<Props> = ({
  sidebarBg,
  toggled,
  handleToggleSidebar,
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const path = useLocation().pathname;

  return (
    <ProSidebar
      image={sidebarBg}
      collapsed={collapsed}
      breakPoint="md"
      toggled={toggled}
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Toggle
            className={`${collapsed ? "" : "active"}`}
            onClick={() => setCollapsed(!collapsed)}
          >
            <span></span>
            <span></span>
            <span></span>
          </Toggle>
          <Header>People's Safety</Header>
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle" popperArrow={true}>
          <MenuItem active={path === "/users"} icon={<FaUsers />}>
            <MenuText>Users</MenuText>
            <Link to="/users" />
          </MenuItem>
          <MenuItem active={path === "/inspections"} icon={<FaList />}>
            <MenuText>Inspections</MenuText> <Link to="/inspections" />
          </MenuItem>
        </Menu>
      </SidebarContent>

      <SidebarFooter>
        <div
          className="sidebar-btn-wrapper"
          style={{ padding: "20px 24px", textAlign: "center" }}
        >
          <a
            href="https://github.com/haZya"
            target="_blank"
            className="sidebar-btn"
            rel="noopener noreferrer"
          >
            <FaGithub />
            <span> Developed by haZya</span>
          </a>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Sidebar;
